/* Brand- LXR */

/* ============================= TYPOGRAPHY ============================= */

@font-face {
  font-family: Linotype Didot Bold;
  font-display: swap;
  src: url('/modules/assets/fonts/linotype-didot/bold.woff') format('woff');
}

@font-face {
  font-family: Linotype Didot Regular;
  font-display: swap;
  src: url('/modules/assets/fonts/linotype-didot/roman.woff') format('woff');
}

@font-face {
  font-family: Bookmania Regular;
  font-display: swap;
  src: url('/modules/assets/fonts/bookmania/regular.woff') format('woff');
}

@font-face {
  font-family: Bookmania SemiBold;
  font-display: swap;
  src: url('/modules/assets/fonts/bookmania/semi-bold.woff') format('woff');
}

/* ============================= THEMING ============================= */

.theme-ol {
  --color-primary: 51 78 58;
  --color-primary-alt: 51 78 58;
  --color-secondary: 51 78 58;
  --color-secondary-alt: 51 78 58;
  --color-brand: 51 78 58;
  --color-brand-alt: 51 78 58;
  --font-headline: 'Linotype Didot Regular';
  --font-headline-brand: 'Linotype Didot Bold';
  --font-sans: 'Bookmania Regular';
  --font-serif: 'Bookmania SemiBold';
  --color-bg-light: 252 250 248;
  --color-bg-dark: 11 66 40;
  --color-text-heading: var(--color-primary);
  --font-headline-weight: 400;
  --heading-style-preset: italic;
  --heading-spacing-preset: normal;

  /* property-ui theme */
  --color-ol-accent: 51 78 58;

  /* button styles */
  --btn-base-border-radius: none;

  --shop-form-btn-text-color: var(--color-primary);
  --shop-form-btn-border-color: var(--color-primary);

  /* Image Headliner styles */
  --image-headliner-link-active-color: var(--color-bg-light);
  --image-headliner-control-color-default: var(--color-text-inverse);

  /* dynamic grid */
  --dynamic-grid-item-wom-header-font-family: var(--font-serif);
  --dynamic-grid-item-wom-header-font-style: normal;
  --dynamic-grid-item-wom-link-anchor-margin-top: 0.75rem;
  --dynamic-grid-item-wom-link-anchor-margin-bottom: 1.5rem;
}

.theme-ol h1,
.theme-ol h2,
.theme-ol h3,
.theme-ol h4,
.theme-ol h5,
.theme-ol h6 {
  font-variant-ligatures: common-ligatures;
}

/* ============================= SEARCH ============================= */

.theme-ol .searchWidgetBrand form .text-5xl {
  @apply font-headline-brand;
  @apply -mb-2 mt-2 mr-1;
  @apply font-medium;
}

/* ============================= Tabbed Section ============================= */

.theme-ol .tabbedSection div[role='tablist'] button.font-headline {
  @apply not-italic;
}

/* //TODO this will be replaced with tailwind breakpoint */
@media screen and (min-width: 2530px) {
  #carousel-overlay-heading {
    @apply text-7xl leading-relaxed;
  }
}

.theme-ol .tabbedSection .tabHeadline {
  @apply lg:mb-3;
}

.theme-ol .tabbedSection .tab-description {
  @apply mb-6;
}

.theme-ol .tabbedSection button[role='tab'] {
  @apply lg:text-xl;
}

/* ============================= End of File ============================= */

.theme-ol {
  --color-lxr-green: #334e3a;
  --color-lxr-brown: #8b634b;
  --color-lxr-light-gray: #ececed;

  .surface-base,
  .surface-alternate {
    --ds-color-headline: var(--color-lxr-green);
    --ds-color-interactive: var(--color-lxr-brown);
    --ds-color-interactive-hover: var(--color-lxr-green);
  }

  .surface-alternate {
    --ds-color-background: var(--color-lxr-light-gray);
  }

  .surface-inverse {
    --ds-color-background: var(--color-lxr-green);
    --ds-color-interactive-hover: var(--color-neutral-300);
  }

  .theme-btn {
    @apply rounded-none;
  }
  .theme-heading {
    @apply italic;
  }
}
