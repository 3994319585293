/* Brand- LivSmart Studios */

/* ============================= TYPOGRAPHY ============================= */

@font-face {
  font-family: Sharp Grotesk;
  font-weight: 400;
  font-display: swap;
  src: url('/modules/assets/fonts/sharp-grotesk/regular.woff2') format('woff2');
}

@font-face {
  font-family: Sharp Grotesk;
  font-weight: 700;
  font-display: swap;
  src: url('/modules/assets/fonts/sharp-grotesk/bold.woff2') format('woff2');
}

/* ============================= THEMING ============================= */
.theme-ey {
  --color-primary: 77 88 62;
  --color-primary-alt: 36 36 36;
  --color-secondary: 100 63 86;
  --color-secondary-alt: 77 88 62;
  --color-tertiary: 248 187 132;
  --color-tertiary-alt: 254 245 237;
  --color-quarternary: 245 158 79;
  --color-quarternary-alt: 69 79 56;
  --color-brand: 248 187 132;
  --color-brand-alt: 250 207 167;
  --color-bg-light: 255 250 243;
  --color-bg-dark: 77 88 62;
  --color-text-inverse: 255 250 243;
  --font-headline: 'Sharp Grotesk';
  --font-headline-brand: 'Sharp Grotesk';
  --font-sans: 'Sharp Grotesk';
  --color-text-heading: var(--color-primary);
  --heading-spacing-preset: 0em;

  /* property-ui theme */
  --color-ey-accent: 77 88 62;
  --color-ey-icon-accent: 100 63 86;

  --header-base-color: var(--color-bg);
  --header-item-hover-color: var(--color-tertiary-alt);
  --header-item-hover-border-color: var(--color-primary);

  --nav-icon-fill-color: var(--color-primary);
  --nav-icon-active-fill-color: var(--color-primary);

  --nav-item-font-weight: 400;
  --nav-main-link-font-weight: 400;

  --nav-drawer-link-active-font-weight: 400;

  /* button styles */
  --btn-base-border-radius: 0.125rem;
  --btn-base-font-weight: normal;
  --btn-text-transform: uppercase;

  --btn-text-color-dark: var(--color-primary);
  --btn-text-hover-color-dark: var(--color-primary-alt);
  --btn-bg-color-dark: var(--color-bg-light);
  --btn-bg-hover-color-dark: var(--color-quarternary);
  --btn-border-hover-color-dark: var(--color-primary-alt);

  --btn-text-hover-color-dark-outline: var(--color-primary);

  --btn-text-color: var(--color-text-inverse);
  --btn-bg-color: var(--color-secondary);
  --btn-bg-hover-color: var(--color-primary);
  --btn-border-color: var(--color-secondary);

  --btn-text-color-outline: var(--color-primary);
  --btn-text-hover-color-outline: var(--color-primary);
  --btn-border-color-outline: var(--color-primary);
  --btn-border-hover-color-outline: var(--color-primary);

  --btn-text-color-dark-solid: var(--btn-text-color-dark);
  --btn-bg-color-dark-solid: var(--btn-bg-color-dark);
  --btn-bg-hover-color-dark-solid: var(--btn-bg-hover-color-dark);
  --btn-border-color-dark-solid: var(--btn-border-hover-color-dark);
  --btn-border-hover-color-dark-solid: var(--btn-border-hover-color-dark);

  --shop-form-btn-text-color: var(--color-secondary);
  --shop-form-btn-border-color: var(--color-secondary);
  --shop-form-btn-text-hover-color: var(--color-text-inverse);
  --shop-form-btn-bg-hover-color: var(--color-secondary);
  --shop-form-btn-submit-bg-color: var(--color-secondary);
  --shop-form-btn-submit-border-color: var(--color-secondary);
  --shop-form-btn-submit-bg-hover-color: var(--color-secondary-alt);
  --shop-form-btn-submit-border-hover-color: var(--color-secondary-alt);

  /* Image Headliner styles */
  --image-headliner-bg-color: var(--color-bg-light);
  --image-headliner-text-color: var(--color-text);
  --image-headliner-font-weight: 400;
  --image-headliner-font-family: var(--font-sans);
  --image-headliner-link-text-color: var(--color-primary);

  /* Editorial Snippet styles */
  --editorial-snippet-short-description-text-color: var(--color-primary);
  --editorial-snippet-short-description-font-family: var(--font-headline);
  --editorial-snippet-short-description-font-weight: 700;

  /* caption */
  --caption-main-font-weight: 400;

  /* Horizontal Tabbed styles */
  --horizontal-tabbed-list-scrollable-border-b-color-dark: var(--color-bg-light);
  --horizontal-tabbed-list-button-active-border-b-color: var(--color-secondary);
  --horizontal-tabbed-list-button-active-border-b-color-light: var(--color-secondary);
  --horizontal-tabbed-list-button-active-border-b-color-dark: var(--color-brand-alt);

  /* Vertical Tabbed styles*/
  --vertical-tabbed-accordion-border-color: var(--color-primary-alt);
  --vertical-tabbed-accordion-border-color-light: var(--color-primary-alt);
  --vertical-tabbed-button-inactive-text-color: var(--color-primary);
  --vertical-tabbed-button-inactive-bg: var(--color-bg-light);
  --vertical-tabbed-button-inactive-border-color: var(--color-primary-alt);
  --vertical-tabbed-button-inactive-hover-text-color: var(--color-secondary);
  --vertical-tabbed-button-inactive-bg-dark: var(--color-bg-dark);
  --vertical-tabbed-button-inactive-bg-light: var(--color-bg);
  --vertical-tabbed-button-inactive-border-color-light: var(--color-primary-alt);
  --vertical-tabbed-button-inactive-text-color-light: var(--color-primary);
  --vertical-tabbed-button-inactive-hover-text-color-light: var(--color-secondary);
  --vertical-tabbed-button-inactive-border-color-dark: var(--color-bg-light);
  --vertical-tabbed-button-inactive-text-color-dark: var(--color-text-inverse);
  --vertical-tabbed-button-inactive-hover-text-color-dark: var(--color-brand-alt);
  --vertical-tabbed-button-active-font-weight: 400;
  --vertical-tabbed-button-active-border-color: var(--color-primary-alt);
  --vertical-tabbed-button-active-text-color: var(--color-primary);
  --vertical-tabbed-button-active-border-color-light: var(--color-primary-alt);
  --vertical-tabbed-button-active-text-color-light: var(--color-primary);
  --vertical-tabbed-button-active-bg-dark: var(--color-quarternary-alt);
  --vertical-tabbed-button-active-border-color-dark: var(--color-bg-light);
  --vertical-tabbed-button-active-before-bg-dark: var(--color-brand-alt);
  --vertical-tabbed-panel-border-color: var(--color-primary-alt);
  --vertical-tabbed-panel-border-color-light: var(--color-primary-alt);
  --vertical-tabbed-panel-border-color-dark: var(--color-bg-light);
  /* dynamic grid */
  --dynamic-grid-item-header-color: var(--color-text-inverse);
  --dynamic-grid-item-wom-header-font-size: 1.5rem;
  --dynamic-grid-item-wom-header-line-height: 2rem;
  /* card carousel */
  --card-carousel-list-item-border-color: var(--color-primary-alt);
  --card-carousel-list-item-border-color-light: var(--color-primary-alt);
}
@screen sm {
  .theme-ey {
    /* Patchwork Grid Row */
    --patchwork-grid-row-headline-font-size: 1.875rem;
    --patchwork-grid-row-headline-line-height: 2.25rem;
    --dynamic-grid-item-wom-header-font-size: 1.875rem;
    --dynamic-grid-item-wom-header-line-height: 2.25rem;
  }
}

.theme-ey {
  --color-livsmart-green: #4d583e;
  --color-livsmart-off-white: #fffaf3;
  --color-livsmart-carbon: #242424;
  --color-livsmart-blue: #90add2;
  --color-livsmart-gold: #f59e4f;
  --color-livsmart-gold-300: #f8bb84;
  --color-livsmart-amber: #e16435;
  --color-livsmart-plum: #643f56;

  .surface-base,
  .surface-alternate {
    --ds-color-headline: var(--color-livsmart-green);
    --ds-color-interactive: var(--color-livsmart-green);
    --ds-color-interactive-hover: var(--color-livsmart-plum);
  }

  .surface-alternate {
    --ds-color-background: var(--color-livsmart-off-white);
    --ds-color-headline: var(--color-livsmart-plum);
    --ds-color-interactive: var(--color-livsmart-plum);
    --ds-color-interactive-hover: var(--color-livsmart-green);
  }

  .surface-inverse {
    --ds-color-background: var(--color-livsmart-green);
    --ds-color-interactive: var(--color-livsmart-off-white);
    --ds-color-interactive-hover: var(--color-livsmart-gold-300);
  }

  .theme-btn {
    @apply rounded-sm uppercase font-normal;
  }
}
