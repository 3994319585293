/* Brand- Hampton
 */
@font-face {
  font-family: Hampton Sans;
  font-display: swap;
  src: url('/modules/assets/fonts/hampton-sans/HamptonSans-Regular.otf') format('opentype');
}

@font-face {
  font-family: Hampton Sans;
  font-display: swap;
  font-weight: bold;
  src: url('/modules/assets/fonts/hampton-sans/HamptonSans-Bold.otf') format('opentype');
}

@font-face {
  font-family: Hampton Serif;
  font-display: swap;
  src: url('/modules/assets/fonts/hampton-serif/HamptonSerif-Regular.otf') format('opentype');
}

.theme-hp {
  --color-primary: 0 71 181;
  --color-primary-alt: 22 33 106;
  --color-secondary: 22 33 106;
  --color-secondary-alt: 189 212 229;
  --color-quarternary: 228 240 245;
  --color-tertiary: 0 38 102;
  --color-tertiary-alt: 207 50 66;
  --color-brand: 22 33 106;
  --color-brand-alt: 22 33 106;
  --font-body: var(--font-serif);
  --font-headline: 'Hampton Sans';
  --font-sans: 'Hampton Sans';
  --font-serif: 'Hampton Serif';
  --color-bg-light: 189 212 229;
  --color-bg-dark: 0 71 181;

  /* property-ui theme */
  --color-hp-accent: 0 71 181;
  --color-hp-icon-accent: 22 33 106;

  /* heading presets */
  --color-text-heading: var(--color-primary);
  --heading-transform-preset: uppercase;
  --heading-spacing-preset: normal;

  /* heading sizes */
  --font-headline-sm: 1rem;
  --font-headline-base: 1.125rem;
  --font-headline-lg: 1.25rem;
  --font-headline-xl: 1.5rem;
  --font-headline-2xl: 1.875rem;
  --font-headline-3xl: 2.25rem;
  --font-headline-4xl: 3rem;
  --font-headline-5xl: 3.75rem;
  --font-headline-6xl: 4.5rem;
  --font-headline-7xl: 6rem;
  --font-headline-8xl: 8rem;

  /* lineheight sizes */
  --headline-lineheight-sm: 1.5rem;
  --headline-lineheight-base: 1.75rem;
  --headline-lineheight-lg: 1.75rem;
  --headline-lineheight-xl: 2rem;
  --headline-lineheight-2xl: 2.25rem;
  --headline-lineheight-3xl: 2.5rem;
  --headline-lineheight-4xl: 1;

  /* button presets */

  --btn-font-family: var(--font-serif);
  --btn-base-font-weight: 400;

  --btn-room-card-top-text-font-weight: 400;

  /* header presets */

  --nav-item-font-family: var(--font-serif);
  --nav-item-font-weight: 400;
  --nav-main-link-font-family: var(--font-serif);
  --nav-main-link-font-weight: 400;
  --nav-item-text-font-size: 1rem;
  --nav-item-text-line-height: 1.5rem;

  --shop-form-btn-text-color: var(--color-brand);
  --shop-form-btn-font-family: var(--font-serif);
  --shop-form-btn-border-color: var(--color-brand);
  --shop-form-btn-font-weight: 400;
  --shop-form-btn-submit-font-family: var(--font-serif);
  --shop-form-btn-submit-font-weight: 400;

  /* Image Headliner styles */
  --image-headliner-link-active-color: var(--color-bg-light);
  --image-headliner-control-color-default: var(--color-text-inverse);

  /* Horizontal tabbed styles */
  --horizontal-tabbed-list-button-active-border-b-color: var(--color-primary);
  --horizontal-tabbed-list-button-active-border-b-color-light: var(--color-primary);

  --horizontal-tabbed-list-button-active-bg-no-icon-light: var(--color-bg);
  --horizontal-tabbed-list-button-active-text-color-no-logo-icon-light: var(--color-primary);
  --horizontal-tabbed-list-button-active-border-b-color-light: var(--color-primary);
  --horizontal-tabbed-list-scrollable-border-b-color-light: var(--color-primary);
  --horizontal-tabbed-list-scrollable-border-b-color: var(--color-primary);

  --horizontal-tabbed-list-button-active-bg-no-icon-dark: var(--color-secondary-alt);
  --horizontal-tabbed-list-button-active-text-color-no-logo-icon-dark: var(--color-primary);
  --horizontal-tabbed-list-button-active-border-b-color-dark: var(--color-tertiary-alt);
  --horizontal-tabbed-list-scrollable-border-b-color-dark: var(--color-tertiary);
  --horizontal-tabbed-list-button-active-bg-no-icon-default: var(--color-quarternary);
  --horizontal-tabbed-list-active-no-icon-text-color-dark: var(--color-primary);
  --horizontal-tabbed-list-button-active-text-color-no-icon-dark: var(--color-primary);

  --vertical-tabbed-button-active-before-bg-dark: var(--color-tertiary-alt);
  --vertical-tabbed-button-inactive-bg-dark: var(--color-secondary-alt);
  --vertical-tabbed-button-active-border-color-dark: var(--color-primary);
  --vertical-tabbed-button-inactive-border-color-dark: var(--color-primary);
  --vertical-tabbed-button-inactive-text-color-dark: var(--color-primary);
  --vertical-tabbed-button-active-bg: var(--color-bg);
  --vertical-tabbed-button-inactive-bg: var(--color-quarternary);
  --vertical-tabbed-button-active-border-color: var(--color-primary);
  --vertical-tabbed-button-inactive-border-color: var(--color-primary);
  --vertical-tabbed-panel-border-color: var(--color-primary);
  --vertical-tabbed-button-active-border-color-light: var(--color-primary);
  --vertical-tabbed-button-inactive-border-color-light: var(--color-primary);
  --vertical-tabbed-panel-border-color-light: var(--color-primary);
  --vertical-tabbed-button-active-text-color-light: var(--color-primary);
  --vertical-tabbed-button-inactive-text-color-light: var(--color-primary);
  --vertical-tabbed-button-active-text-color: var(--color-primary);
  --vertical-tabbed-button-inactive-text-color: var(--color-primary);

  --vertical-tabbed-button-inactive-font-family: var(--font-serif);
  --vertical-tabbed-button-active-font-family: var(--font-serif);

  /* dynamic grid */
  --dynamic-grid-item-wom-header-padding-bottom: 1rem;
  --dynamic-grid-item-wom-header-padding-top: 1rem;
  --dynamic-grid-item-wom-header-color: var(--color-primary);
  --dynamic-grid-item-wom-header-no-desc-padding-bottom: 0;
  --dynamic-grid-item-wom-header-no-desc-padding-top: 1rem;
  --dynamic-grid-item-wom-link-anchor-font-family: var(--font-serif);
  --dynamic-grid-item-wom-link-anchor-color-default: var(--color-brand);
  --dynamic-grid-desc-text-color-light: var(--color-brand);

  --card-carousel-background-color: transparent;
  --card-carousel-background-color-light: transparent;
  --card-carousel-background-color-dark: transparent;
  --card-carousel-list-item-border-color-light: var(--color-primary);
  --card-carousel-list-item-border-color-dark: var(--color-tertiary);
}

@screen lg {
  .theme-hp {
    --dynamic-grid-item-wom-header-font-size: 1.875rem;
    --dynamic-grid-item-wom-header-line-height: 2.25rem;
  }
}

.theme-hp p {
  @apply font-serif;
}

.theme-hp [data-osc-product='shop-input-label'] {
  @apply text-lg;
}

.theme-hp [data-osc-product='back-to-top'] {
  @apply uppercase text-lg;
}

/* ex of why try to be as pointed as possible when targeting an element.
IE, don’t target all children of something. Easy to overlap and not know where styles are coming from.
this will apply text-base to the label that uses a span
rather target the label and the span specifically
question: is it okay to target elements in this style or should we always have a data-osc-product label equivalent
 */
.theme-hp [data-osc-product='shop-form-location'] div span,
.theme-hp [data-osc-product='shop-form-location'] label span,
.theme-hp [data-osc-product='shop-form-location'] input,
.theme-hp [data-osc-product='search-dates-label'],
.theme-hp [data-osc-product='shop-form-dates'] {
  @apply font-serif;
}

.theme-hp [data-osc-product='shop-form-dates'] .text-primary {
  @apply text-brand;
}

.theme-hp footer {
  @apply font-serif;
}

.theme-hp [data-osc-product='footer-help-label'] {
  @apply !normal-case !text-lg;
}

.theme-hp [data-osc-product='shop-form-location'] svg {
  height: 18px;
  width: 18px;
}

.theme-hp [data-osc-product='header-signup-link'] span {
  @apply !font-normal;
}

.theme-hp [data-osc-product='carousel-label'] {
  @apply font-sans;
  @apply font-bold;
}

.theme-hp a.text-primary {
  @apply text-brand;
}

.theme-hp [data-testid='text-highlight-wrapper'] > * {
  @apply text-brand;
  @apply font-serif;
}

.theme-hp [data-testid='text-highlight-wrapper'] a span {
  @apply text-brand;
}

.theme-hp [data-osc-product='osc-caption'] {
  @apply font-serif font-normal;
}

.theme-hp [data-osc-product='alert-label'] {
  @apply font-normal;
}

.theme-hp [data-osc-product='date-picker-buttons'] {
  @apply text-brand;
}

.theme-hp footer a span {
  @apply text-brand;
}

.theme-hp footer a {
  @apply text-brand;
}

.theme-hp footer a span text-primary {
  @apply text-brand;
}

.theme-hp h1,
.theme-hp h2,
.theme-hp h3,
.theme-hp h4,
.theme-hp h5,
.theme-hp h6 {
  font-variant-ligatures: common-ligatures;
}

.theme-hp {
  --color-hampton-blue: #0047b5;
  --color-hampton-light-blue: #bdd4e5;
  --color-hampton-dark-blue: #002666;
  --color-hampton-red: #cf3242;
  --color-hampton-dark-red: #801f40;

  .surface-base,
  .surface-alternate {
    --ds-color-text: var(--color-hampton-blue);
    --ds-color-headline: var(--color-hampton-blue);
    --ds-color-interactive: var(--color-hampton-blue);
    --ds-color-interactive-hover: var(--color-hampton-dark-blue);
  }

  .surface-alternate {
    --ds-color-border: var(--color-hampton-dark-blue);
    --ds-color-background: var(--color-hampton-light-blue);
  }

  .surface-inverse {
    --ds-color-border: var(--color-black);
    --ds-color-background: var(--color-hampton-blue);
    --ds-color-interactive-hover: var(--color-hampton-light-blue);
  }

  .theme-heading {
    @apply uppercase;
  }
  .theme-btn {
    @apply font-serif normal-case;
  }
  .theme-link {
    @apply font-serif;
  }
}
