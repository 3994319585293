/* Brand- Canopy */
@font-face {
  font-family: Domaine Extra Bold;
  font-display: swap;
  src: url('/modules/assets/fonts/domaine-display/extrabold.woff2') format('woff2');
}

.theme-py {
  --color-primary: 78 78 78;
  --color-primary-alt: 78 78 78;
  --color-secondary: 0 0 0;
  --color-secondary-alt: 0 0 0;
  --color-brand: 254 80 0;
  --color-brand-alt: 254 80 0;
  --font-headline: 'Domaine Extra Bold';
  --color-bg-light: 244 244 244;
  --color-text-heading: var(--color-primary);
  --heading-spacing-preset: normal;

  /* property-ui theme */
  --color-py-accent: 78 78 78;
  --color-py-icon-accent: 254 80 0;

  /* Image Headliner styles */
  --image-headliner-control-color-default: var(--color-text-inverse);
}

/* EFFECTS */
.theme-py .bg-img-overlay {
  @apply bg-repeat bg-auto;
  background-image: url('/modules/assets/img/brand/PY/backgrounds/linen.png');
}

.theme-py h1,
.theme-py h2,
.theme-py h3,
.theme-py h4,
.theme-py h5,
.theme-py h6 {
  font-variant-ligatures: common-ligatures;
}

.theme-py {
  --color-canopy-orange: #fe5000;
  --color-canopy-gray: #6e6e6e;
  --color-canopy-light-gray: #e6e6e6;

  .surface-base,
  .surface-alternate {
    --ds-color-border: var(--color-neutral-400);
    --ds-color-interactive: var(--color-neutral-950);
  }

  .surface-alternate {
    --ds-color-background: var(--color-canopy-light-gray);
  }

  .surface-inverse {
    --ds-color-border: var(--color-black);
    --ds-color-background: var(--color-neutral-950);
    --ds-color-interactive-hover: var(--color-canopy-orange);
  }
}
