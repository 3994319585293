/* Brand- Curio */

/* ============================= TYPOGRAPHY ============================= */

@font-face {
  font-family: Miller Banner Bold;
  font-display: swap;
  src: url('/modules/assets/fonts/miller-banner/bold.woff') format('woff');
}

@font-face {
  font-family: Gotham Book;
  font-display: swap;
  src: url('/modules/assets/fonts/gotham/book.woff') format('woff');
}

@font-face {
  font-family: Gotham Medium;
  font-display: swap;
  src: url('/modules/assets/fonts/gotham/medium.woff') format('woff');
}

@font-face {
  font-family: Gotham Bold;
  font-display: swap;
  src: url('/modules/assets/fonts/gotham/bold.woff') format('woff');
}

/* ============================= THEMING ============================= */

.theme-qq {
  --color-primary: 77 93 112;
  --color-primary-alt: 77 93 112;
  --color-secondary: 87 167 185;
  --color-secondary-alt: 87 167 185;
  --color-brand: 77 93 112;
  --color-brand-alt: 77 93 112;
  --color-bg-light: 235 236 240;
  --color-bg-dark: 77 93 112;
  --font-headline: 'Miller Banner Bold';
  --font-headline-brand: 'Gotham Bold';
  --font-serif: 'Gotham Medium';
  --font-sans: 'Gotham Book';
  --color-text-heading: var(--color-primary);
  --heading-spacing-preset: normal;

  /* property-ui theme */
  --color-qq-accent: 77 93 112;

  /* button styles */
  --btn-base-border-radius: none;

  --btn-bg-color: var(--transparent);
  --btn-bg-hover-color: var(--transparent);
  --btn-text-color: var(--color-primary);
  --btn-text-hover-color: var(--color-primary);

  --btn-bg-color-solid: var(--color-primary);
  --btn-text-color-solid: var(--color-text-inverse);
  --btn-bg-hover-color-solid: var(--color-primary);
  --btn-text-hover-color-solid: var(--color-text-inverse);

  --btn-bg-color-dark: var(--color-primary);
  --btn-bg-hover-color-dark: var(--color-primary);
  --btn-border-color-dark: var(--color-text-inverse);
  --btn-border-hover-color-dark: var(--color-text-inverse);
  --btn-text-color-dark: var(--color-text-inverse);
  --btn-text-hover-color-dark: var(--color-text-inverse);

  --btn-bg-color-dark-solid: var(--color-bg);
  --btn-border-hover-color-dark-solid: var(--color-text-inverse);
  --btn-text-hover-color-dark-solid: var(--color-text-inverse);

  --shop-form-btn-text-color: var(--color-primary);
  --shop-form-btn-text-hover-color: var(--color-primary);
  --shop-form-btn-border-color: var(--color-primary);
  --shop-form-btn-border-hover-color: var(--color-primary);

  /* Image Headliner styles */
  --image-headliner-bg-color: var(--color-primary);
  --image-headliner-link-active-color: var(--color-bg-light);
  --image-headliner-control-color-default: var(--color-text-inverse);

  /* dynamic grid */
  --dynamic-grid-wom-headline-wrapper-margin-inline-end: 1.5rem;
  --dynamic-grid-wom-headline-wrapper-display: flex;
  --dynamic-grid-wom-headline-wrapper-flex-direction: column;
  --dynamic-grid-wom-headline-wrapper-gap: 0.5rem;
  --dynamic-grid-wom-headline-grid-row: span 3 / span 3;
  --dynamic-grid-wom-subheader-font-size: 1rem;
  --dynamic-grid-wom-subheader-line-height: 1.5rem;
  --dynamic-grid-wom-subheader-font-weight: 500;
  --dynamic-grid-wom-subheader-color: var(--color-primary-alt);
  --dynamic-grid-wom-subheader-margin-top: 1.75rem;
  --dynamic-grid-wom-subheader-font-family: var(--font-headline-brand);
  --dynamic-grid-wom-grid-wrapper-padding-top: 1rem;
  --dynamic-grid-item-wom-header-font-family: var(--font-serif);
  --dynamic-grid-item-wom-header-font-style: normal;
  --dynamic-grid-item-wom-header-font-weight: 400;
}
@screen md {
  .theme-qq {
    --dynamic-grid-wom-headline-wrapper-flex-direction: row;
  }
}
@screen lg {
  .theme-qq {
    --dynamic-grid-wom-headline-grid-row: span 1 / span 1;
    --dynamic-grid-wom-header-font-size: 3rem;
    --dynamic-grid-wom-header-line-height: 1;
    --dynamic-grid-wom-description-margin-inline-start: 0.5rem;
  }
}
/* ============================= HEADER ============================= */

.theme-qq header nav li span[aria-hidden='true'] svg g {
  @apply fill-[#FFFFFF];
}

/* ============================= SEARCH ============================= */

.theme-qq .searchWidgetBrand form .text-5xl {
  @apply font-headline;
}

/* ============================= FOOTER ============================= */

.theme-qq footer {
  background-color: #51657d;
  color: rgb(255 255 255);
  --color-primary: 255 255 255;
  --color-primary-alt: 255 255 255;
  --color-brand: 255 255 255;
  --color-brand-alt: 255 255 255;
  --color-text: 255 255 255;
  --color-text-alt: 255 255 255;
}

.theme-qq h1,
.theme-qq h2,
.theme-qq h3,
.theme-qq h4,
.theme-qq h5,
.theme-qq h6 {
  font-variant-ligatures: common-ligatures;
}

.theme-qq {
  --color-curio-charcoal: #231f20;
  --color-curio-blue-gray: #4f647d;
  --color-curio-dark-blue-gray: #474b59;
  --color-curio-cool-gray: #939598;
  --color-curio-light-blue: #5cb6d2;
  --color-curio-off-white: #ebecf0;

  .surface-base,
  .surface-alternate {
    --ds-color-interactive: var(--color-curio-dark-blue-gray);
    --ds-color-interactive-hover: var(--color-neutral-950);
  }

  .surface-alternate {
    --ds-color-background: var(--color-neutral-50);
  }

  .surface-inverse {
    --ds-color-border: var(--color-curio-dark-blue-gray);
    --ds-color-background: var(--color-curio-dark-blue-gray);
    --ds-color-interactive-hover: var(--color-neutral-400);
  }

  .theme-btn {
    @apply rounded-none;
  }
}
