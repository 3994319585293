/* Brand- Homewood */
@font-face {
  font-family: Feijoa Display Web;
  font-display: swap;
  src: url('/modules/assets/fonts/feijoa-display/feijoa-display-webfont.woff2') format('woff2');
}

.theme-hw {
  --color-primary: 160 95 10;
  --color-primary-alt: 160 95 10;
  --color-secondary: 0 113 103;
  --color-secondary-alt: 0 113 103;
  --color-brand: 26 34 44;
  --color-brand-alt: 26 34 44;
  --font-headline: 'Feijoa Display Web';
  --color-bg-light: 235 235 235;
  --color-bg-dark: 76 92 117;
  --color-text-heading: var(--color-primary);
  --heading-spacing-preset: normal;

  /* property-ui theme */
  --color-hw-accent: 160 95 10;
  --color-hw-icon-accent: 26 34 44;

  /* Image Headliner styles */
  --image-headliner-control-color-default: var(--color-text-inverse);
}

/* EFFECTS */
.theme-hw .bg-img-overlay {
  clip-path: inset(0px 4px);
  background-image: url('/modules/assets/img/brand/HW/backgrounds/blinds-background.png');
}

.theme-hw h1,
.theme-hw h2,
.theme-hw h3,
.theme-hw h4,
.theme-hw h5,
.theme-hw h6 {
  font-variant-ligatures: common-ligatures;
}

.theme-hw {
  --color-homewood-sky: #8aa6b7;
  --color-homewood-dusk: #4c5c75;
  --color-homewood-deep-night: #051c2c;
  --color-homewood-cloud: #9aa3af;
  --color-homewood-air: #e0e0e0;
  --color-homewood-dawn: #bf710a;
  --color-homewood-glow: #e8dfce;

  .surface-base,
  .surface-alternate {
    --ds-color-interactive: var(--color-homewood-deep-night);
    --ds-color-interactive-hover: var(--color-homewood-dusk);
  }

  .surface-alternate {
    --ds-color-background: var(--color-homewood-sky);
    --ds-color-interactive: var(--color-neutral-950);
    --ds-color-interactive-hover: var(--color-black);
  }

  .surface-inverse {
    --ds-color-text: var(--color-homewood-sky);
    --ds-color-headline: var(--color-homewood-sky);
    --ds-color-border: var(--color-neutral-600);
    --ds-color-background: var(--color-homewood-deep-night);
    --ds-color-interactive: var(--color-homewood-glow);
    --ds-color-interactive-hover: var(--color-homewood-sky);
  }
}
