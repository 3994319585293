/* Brand- AutoCamp */

/* ============================= TYPOGRAPHY ============================= */

@font-face {
  font-family: Arizona Mix;
  font-weight: 700;
  font-display: swap;
  src: url('/modules/assets/fonts/arizona-mix/bold.woff') format('woff');
}

@font-face {
  font-family: Arizona Mix;
  font-weight: 300;
  font-display: swap;
  src: url('/modules/assets/fonts/arizona-mix/light.woff') format('woff');
}

@font-face {
  font-family: Arizona Mix;
  font-weight: 400;
  font-display: swap;
  src: url('/modules/assets/fonts/arizona-mix/regular.woff') format('woff');
}

@font-face {
  font-family: Banana Grotesk;
  font-display: swap;
  src: url('/modules/assets/fonts/banana-grotesk/regular.woff') format('woff');
}

@font-face {
  font-family: Suisse Mono;
  font-weight: 700;
  font-display: swap;
  src: url('/modules/assets/fonts/suisse-mono/bold.woff') format('woff');
}

@font-face {
  font-family: Suisse Mono;
  font-weight: 300;
  font-display: swap;
  src: url('/modules/assets/fonts/suisse-mono/light.woff') format('woff');
}

@font-face {
  font-family: Suisse Mono;
  font-weight: 400;
  font-display: swap;
  src: url('/modules/assets/fonts/suisse-mono/regular.woff') format('woff');
}

/* ============================= THEMING ============================= */

.theme-ou {
  --color-primary: 44 38 27;
  --color-primary-alt: 0 0 0;
  --color-secondary: 255 250 235;
  --color-secondary-alt: 219 196 158;
  --color-tertiary: 37 112 56;
  --color-quarternary: 255 255 0;
  --color-quarternary-alt: 44 38 27;
  --color-brand: 44 38 27;
  --color-brand-alt: 44 38 27;
  --color-bg-light: 245 228 196;
  --color-bg-dark: 44 38 27;
  --color-text-inverse: 255 250 235;
  --font-headline: 'Arizona Mix';
  --font-headline-brand: 'Arizona Mix';
  --font-mono: 'Suisse Mono';
  --font-sans: 'Banana Grotesk';
  --color-text-heading: var(--color-primary);
  --heading-spacing-preset: -0.05em;
  --font-headline-weight: 300;

  /* property-ui theme */
  --color-ou-primary: 255 250 235;
  --color-ou-primary-contrast: 44 38 27;
  --color-ou-secondary: 245 228 196;
  --color-ou-secondary-contrast: 44 38 27;
  --color-ou-tertiary: 44 38 27;
  --color-ou-tertiary-contrast: 255 250 235;
  --color-ou-accent: 44 38 27;

  /* heading sizes */
  --font-headline-sm: 1rem;
  --font-headline-base: 1.125rem;
  --font-headline-lg: 1.25rem;
  --font-headline-xl: 1.5rem;
  --font-headline-2xl: 1.875rem;
  --font-headline-3xl: 2.25rem;
  --font-headline-4xl: 3rem;
  --font-headline-5xl: 3.75rem;
  --font-headline-6xl: 4.5rem;
  --font-headline-7xl: 6rem;
  --font-headline-8xl: 8rem;

  /* lineheight sizes */
  --headline-lineheight-sm: 1.5rem;
  --headline-lineheight-base: 1.75rem;
  --headline-lineheight-lg: 1.75rem;
  --headline-lineheight-xl: 2rem;
  --headline-lineheight-2xl: 2.25rem;
  --headline-lineheight-3xl: 2.5rem;
  --headline-lineheight-4xl: 1;

  /* header styles */
  --header-base-color: var(--color-secondary);
  --header-item-hover-border-color: var(--color-primary);
  --header-item-hover-color: var(--color-bg-light);
  --nav-bottom-area-base-color: var(--color-secondary);

  /* button styles */
  --btn-base-border-radius: 99999px;
  --btn-base-font-weight: 400;
  --btn-base-letter-spacing: 0.025em;
  --btn-font-family: var(--font-mono);
  --btn-text-transform: uppercase;
  --btn-room-card-padding-left: 1.5rem;
  --btn-room-card-padding-right: 1.5rem;

  --btn-bg-color: var(--color-quarternary);
  --btn-bg-hover-color: var(--color-quarternary-alt);
  --btn-border-color: var(--color-primary);
  --btn-border-hover-color: var(--color-quarternary);
  --btn-text-color: var(--color-text);
  --btn-text-hover-color: var(--color-quarternary);

  --btn-text-color-solid: var(--color-primary);

  --btn-border-color-outline: var(--color-brand);
  --btn-text-color-outline: var(--color-brand);
  --btn-bg-hover-color-outline: var(--color-brand);
  --btn-border-hover-color-outline: var(--color-quarternary);
  --btn-text-hover-color-outline: var(--color-quarternary);

  --btn-bg-hover-color-dark: var(--color-quarternary-alt);
  --btn-border-hover-color-dark: var(--color-quarternary);
  --btn-text-hover-color-dark: var(--color-quarternary);

  --btn-bg-hover-color-dark-solid: var(--btn-bg-hover-color-dark);
  --btn-border-hover-color-dark-solid: var(--btn-border-hover-color-dark);
  --btn-text-hover-color-dark-solid: var(--btn-text-hover-color-dark);

  --shop-form-btn-text-color: var(--color-primary);
  --shop-form-btn-border-color: var(--color-primary);
  --shop-form-btn-text-hover-color: var(--color-quarternary);
  --shop-form-btn-border-hover-color: var(--color-quarternary);
  --shop-form-btn-bg-hover-color: var(--color-primary);

  --shop-form-btn-submit-text-color: var(--color-primary);
  --shop-form-btn-submit-bg-color: var(--color-quarternary);
  --shop-form-btn-submit-text-hover-color: var(--color-quarternary);
  --shop-form-btn-submit-border-hover-color: var(--color-quarternary);
  --shop-form-btn-submit-bg-hover-color: var(--color-primary);

  /* Image Headliner styles */
  --image-headliner-text-color: var(--color-primary);
  --image-headliner-font-weight: 400;
  --image-headliner-font-family: var(--font-sans);
  --image-headliner-link-text-color: var(--color-primary);

  /* alerts */
  --alert-bg-color: var(--color-quarternary);
  --alert-text-color: var(--color-text);
  --alert-icon-color: var(--color-text);

  /* Patchwork Grid */
  --patchwork-grid-description-text-color: var(--color-primary);
  --patchwork-grid-description-text-color-light: var(--color-primary);
  /* Patchwork Grid Row */
  --patchwork-grid-row-description-text-color: var(--color-primary);
  --patchwork-grid-row-description-text-color-light: var(--color-primary);

  --caption-main-font-weight: 400;
  --caption-main-font-family: var(--font-mono);
  --caption-main-text-transform: uppercase;
  --caption-main-letter-spacing: 0.025em;

  /* Horizontal Tabbed styles */
  --horizontal-tabbed-list-scrollable-border-b-color: var(--color-primary);
  --horizontal-tabbed-list-scrollable-border-b-color-light: var(--color-primary);
  --horizontal-tabbed-list-scrollable-border-b-color-dark: var(--color-secondary);
  --horizontal-tabbed-arrow-wrapper-gradient-bg: var(--color-secondary);
  --horizontal-tabbed-list-button-active-border-b-color-dark: var(--color-secondary);

  /* Vertical Tabbed styles */
  --vertical-tabbed-accordion-expanded-bg-color: var(--color-bg-light);
  --vertical-tabbed-accordion-border-color: var(--color-primary);
  --vertical-tabbed-accordion-expanded-bg-color-light: var(--color-secondary-alt);
  --vertical-tabbed-accordion-border-color-light: var(--color-primary);
  --vertical-tabbed-accordion-icon-indicator-fill-color-dark: var(--color-secondary);
  --vertical-tabbed-accordion-expanded-bg-color-dark: var(--color-primary-alt);
  --vertical-tabbed-button-inactive-bg: var(--color-secondary);
  --vertical-tabbed-button-inactive-border-color: var(--color-primary);
  --vertical-tabbed-button-inactive-bg-light: var(--color-bg-light);
  --vertical-tabbed-button-inactive-text-color-light: var(--color-primary);
  --vertical-tabbed-button-inactive-border-color-light: var(--color-primary);
  --vertical-tabbed-button-inactive-bg-dark: var(--color-bg-dark);
  --vertical-tabbed-button-inactive-border-color-dark: var(--color-secondary);
  --vertical-tabbed-button-inactive-text-color-dark: var(--color-secondary);
  --vertical-tabbed-button-inactive-hover-text-color-dark: var(--color-bg-light);
  --vertical-tabbed-button-active-bg: var(--color-bg-light);
  --vertical-tabbed-button-active-border-color: var(--color-primary);
  --vertical-tabbed-button-active-bg-light: var(--color-secondary-alt);
  --vertical-tabbed-button-active-border-color-light: var(--color-primary);
  --vertical-tabbed-button-active-bg-dark: var(--color-primary-alt);
  --vertical-tabbed-button-active-border-color-dark: var(--color-secondary);
  --vertical-tabbed-button-active-text-color-dark: var(--color-text-inverse);
  --vertical-tabbed-button-active-before-bg-dark: var(--color-quarternary);
  --vertical-tabbed-panel-border-color: var(--color-primary);
  --vertical-tabbed-panel-border-color-light: var(--color-primary);
  --vertical-tabbed-panel-border-color-dark: var(--color-secondary);
  /* dynamic grid */
  --dynamic-grid-item-header-font-family: var(--font-headline);
  --dynamic-grid-item-header-color: var(--color-text-inverse);
  --dynamic-grid-item-wom-header-font-weight: 300;
  --dynamic-grid-item-wom-header-color: var(--color-primary);
  /* card carousel */
  --card-carousel-background-color: var(--color-secondary);
  --card-carousel-arrow-button-icon-fill: var(--color-primary);
  --card-carousel-arrow-button-icon-fill-dark: var(--color-secondary);
  --card-carousel-list-item-background-color: var(--color-secondary);
  --card-carousel-list-item-border-color-dark: var(--color-secondary);
  --card-carousel-item-background-color: var(--color-secondary);
  --card-carousel-item-border-color-dark: var(--color-secondary);
}

/* Theme foundation colors */
.theme-ou {
  --color-autocamp-brown: #2c261b;
  --color-autocamp-green: #257038;
  --color-autocamp-biege: #fffaeb;
  --color-autocamp-peach: #f5e4c4;
  --color-autocamp-yellow: #ffff00;

  .surface-base,
  .surface-alternate {
    --ds-color-border: var(--color-neutral-200);
    --ds-color-text: var(--color-autocamp-brown);
    --ds-color-headline: var(--color-autocamp-brown);
    --ds-color-interactive: var(--color-autocamp-brown);
    --ds-color-interactive-hover: var(--color-black);
  }

  .surface-alternate {
    --ds-color-background: var(--color-autocamp-biege);
  }

  .surface-inverse {
    --ds-color-background: var(--color-autocamp-brown);
    --ds-color-border: var(--color-neutral-700);
    --ds-color-interactive: var(--color-autocamp-yellow);
    --ds-color-interactive-hover: var(--color-autocamp-peach);
  }

  .theme-btn {
    @apply font-normal rounded-full font-mono uppercase;
  }

  .theme-heading {
    @apply tracking-tight font-light;
  }
}
