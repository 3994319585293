/* Brand- Spark */
@font-face {
  font-family: Montserrat;
  font-weight: 500;
  font-display: swap;
  src: url('/modules/assets/fonts/montserrat/medium.woff2') format('woff2'),
    url('/modules/assets/fonts/montserrat/medium.woff') format('woff');
}

@font-face {
  font-family: Montserrat;
  font-weight: 400;
  font-display: swap;
  src: url('/modules/assets/fonts/montserrat/regular.woff2') format('woff2'),
    url('/modules/assets/fonts/montserrat/regular.woff') format('woff');
}

.theme-pe {
  --color-primary: 95 63 196;
  --color-primary-alt: 95 63 196;
  /**
   * Spark doesn't technically have a secondary color. These are placeholder values.
   */
  --color-secondary: 0 0 0;
  --color-secondary-alt: 0 0 0;
  --color-brand: 27 72 93;
  --color-brand-alt: 27 72 93;
  --font-headline: 'Montserrat', sans-serif;
  --font-headline-brand: 'Montserrat', sans-serif;
  --font-sans: 'Montserrat', sans-serif;
  --font-serif: 'Montserrat', sans-serif;
  --color-bg-light: 236 232 252;
  --color-bg-dark: 27 72 93;
  --color-text-heading: var(--color-primary);
  --heading-spacing-preset: normal;

  /* property-ui theme */
  --color-pe-accent: 95 63 196;
  --color-pe-icon-accent: 27 72 93;

  /* Image Headliner styles */
  --image-headliner-control-color-default: var(--color-text-inverse);
}

.theme-pe .half-and-half .font-headline {
  @apply font-normal;
  @apply tracking-normal;
}

.theme-pe *:focus {
  @apply outline-primary;
}

.theme-pe h1,
.theme-pe h2,
.theme-pe h3,
.theme-pe h4,
.theme-pe h5,
.theme-pe h6 {
  font-variant-ligatures: common-ligatures;
}

.theme-pe {
  --color-spark-purple: #783fc4;
  --color-spark-light-purple: #d7bee0;
  --color-spark-blue: #1b485d;
  --color-spark-yellow: #d0d202;

  .surface-base,
  .surface-alternate {
    --ds-color-headline: var(--color-spark-blue);
    --ds-color-interactive: var(--color-spark-purple);
    --ds-color-interactive-hover: var(--color-spark-blue);
  }

  .surface-alternate {
    --ds-color-border: var(--color-neutral-50);
    --ds-color-background: var(--color-spark-light-purple);
    --ds-color-interactive: var(--color-black);
  }

  .surface-inverse {
    --ds-color-background: var(--color-spark-purple);
    --ds-color-interactive-hover: var(--color-neutral-50);
    --ds-color-text-muted: var(--color-neutral-200);
  }
}
