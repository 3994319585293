/* Brand - Home2 */
@font-face {
  font-family: Din Next Bold;
  font-display: swap;
  src: url('/modules/assets/fonts/din-next/bold.woff2') format('woff2');
}

.theme-ht {
  --color-primary: 74 124 130;
  --color-primary-alt: 74 124 130;
  --color-secondary: 74 124 130;
  --color-secondary-alt: 74 124 130;
  --color-brand: 74 124 130;
  --color-brand-alt: 74 124 130;
  --font-headline: 'Din Next Bold';
  --color-bg-light: 84 123 129;
  --color-bg-dark: 115 43 144;
  --color-text-heading: var(--color-primary);
  --heading-spacing-preset: normal;

  /* property-ui theme */
  --color-ht-accent: 74 124 130;

  /* button styles */
  --btn-bg-color-light: var(--color-bg);
  --btn-bg-hover-color-light: var(--color-bg);
  --btn-text-color-light: var(--color-primary);
  --btn-text-hover-color-light: var(--color-primary);

  --btn-border-color-dark: 255 255 255;

  /* Image Headliner styles */
  --image-headliner-link-active-color: var(--color-text-inverse);
  --image-headliner-text-color-light: var(--color-text-inverse);
  --image-headliner-link-text-color-light: var(--color-text-inverse);
  --image-headliner-control-color-default: var(--color-text-inverse);

  /* Editorial Snippet styles */
  --editorial-snippet-headline-text-color-light: var(--color-text-inverse);
  --editorial-snippet-short-description-text-color: var(--color-text-alt);
  --editorial-snippet-short-description-text-color-light: var(--color-text-inverse);
  --editorial-snippet-text-body-color-light: var(--color-text-inverse);
  --editorial-snippet-link-text-color-light: var(--color-text-inverse);
  --editorial-snippet-link-active-color-light: var(--color-text-inverse);
  --editorial-snippet-headline-text-color: var(--color-danger);

  /* Video Poster styles */
  --video-poster-headline-text-color-light: var(--color-text-inverse);
  --video-poster-long-description-text-color-light: var(--color-text-inverse);
  /* Patchwork Grid */
  --patchwork-grid-headline-text-color-light: var(--color-text-inverse);
  --patchwork-grid-description-text-color-light: var(--color-text-inverse);

  /* Filmstrip */
  --filmstrip-headline-text-color-light: var(--color-text-inverse);
  --filmstrip-description-text-color-light: var(--color-text-inverse);

  /* Patchwork Grid Row */
  --patchwork-grid-row-headline-text-color-light: var(--color-text-inverse);
  --patchwork-grid-row-description-text-color-light: var(--color-text-inverse);

  /* Brand Tabbed styles (Apply to Both Horizontal and Vertical Tabbed) */
  --tabbed-section-content-header-text-color-light: var(--color-text-inverse);
  --tabbed-section-content-body-text-color-light: var(--color-text-inverse);
  --tabbed-list-headline-text-color-light: var(--color-text-inverse);
  --tabbed-list-description-text-color-light: var(--color-text-inverse);

  /* Tabbed Gallery styles */
  --gallery-tabs-divider-bg-color-light: var(--color-text-inverse);

  /* Horizontal Tabbed styles */
  --horizontal-tabbed-list-scrollable-border-b-color-light: var(--color-text-inverse);
  --horizontal-tabbed-arrow-wrapper-icon-fill-color-light: var(--color-text-inverse);
  --horizontal-tabbed-list-button-active-border-b-color-light: var(--color-bg);

  /* Vertical Tabbed Styles */
  --vertical-tabbed-accordion-text-color-light: var(--color-text-inverse);
  --vertical-tabbed-accordion-border-color-light: var(--color-text-inverse);
  --vertical-tabbed-accordion-expanded-border-color-light: var(--color-text-inverse);
  --vertical-tabbed-accordion-expanded-text-color-light: var(--color-text-inverse);
  --vertical-tabbed-accordion-icon-indicator-fill-color-light: var(--color-bg);
  --vertical-tabbed-accordion-icon-indicator-expanded-fill-color-light: var(--color-bg);
  --vertical-tabbed-button-inactive-hover-text-color-dark: var(--color-bg-dark);
  --vertical-tabbed-button-active-text-color-light: var(--color-text-inverse);
  --vertical-tabbed-button-active-before-bg-light: var(--color-bg);
  --vertical-tabbed-button-active-before-bg-dark: var(--color-bg);
  /* dynamic grid */
  --dynamic-grid-header-color-light: var(--color-text-inverse);
  --dynamic-grid-desc-text-color-light: var(--color-text-inverse);
  --dynamic-grid-wom-header-color-light: var(--color-text-inverse);
  --dynamic-grid-wom-grid-wrap-color-light: var(--color-text-inverse);
  --dynamic-grid-item-wom-link-anchor-color-light: var(--color-text-inverse);
  --dynamic-grid-item-wom-link-anchor-hover-color-light: var(--color-text-inverse);
  --dynamic-grid-item-wom-header-color-light: var(--color-text-inverse);
  --dynamic-grid-item-header-color-dark: var(--color-text-inverse);
  /* Slider */
  --slider-gradient-on-color-light: var(--color-bg);
  --slider-thumb-color-light: var(--color-bg);
  /* card carousel */
  --card-carousel-main-headline-editorial-text-color-light: var(--color-text-inverse);
  --card-carousel-main-headline-standalone-text-color-light: var(--color-text-inverse);
  --card-carousel-short-description-text-color-light: var(--color-text-inverse);
  --card-carousel-arrow-button-border-color-light: var(--color-bg);
  --card-carousel-arrow-button-icon-fill-light: var(--color-text-inverse);
  --card-carousel-list-item-border-color-light: var(--color-bg);
  --card-carousel-item-headline-h4-text-color-light: var(--color-text-inverse);
  --card-carousel-item-description-text-color-light: var(--color-text-inverse);
  --card-carousel-item-headline-h3-text-color-light: var(--color-text-inverse);
  --card-carousel-item-link-text-color-light: var(--color-text-inverse);
  --card-carousel-item-link-anchor-text-decoration-light: var(--color-text-inverse);
}

.theme-ht .bg-img-overlay {
  @apply bg-auto;
  background-image: url('/modules/assets/img/brand/HT/backgrounds/h2_slash_background.svg');
}

.theme-ht h1,
.theme-ht h2,
.theme-ht h3,
.theme-ht h4,
.theme-ht h5,
.theme-ht h6 {
  font-variant-ligatures: common-ligatures;
}

.theme-ht {
  --color-home2-blue: #497c84;
  --color-home2-yellow: #ffc72c;
  --color-home2-green: #a8ad00;
  --color-home2-purple: #87189d;
  --color-home2-brown: #473729;
  --color-home2-orange: #ff8200;
  --color-home2-taupe: #b7a99a;
  --color-home2-cool-gray: #53565a;

  .surface-base,
  .surface-alternate {
    --ds-color-headline: var(--color-home2-blue);
    --ds-color-interactive: var(--color-home2-blue);
    --ds-color-interactive-hover: var(--color-black);
  }

  .surface-alternate {
    --ds-color-background: var(--color-home2-taupe);
    --ds-color-interactive-hover: var(--color-neutral-700);
  }

  .surface-inverse {
    --ds-color-background: var(--color-home2-blue);
    --ds-color-interactive-hover: var(--color-neutral-50);
    --ds-color-text-muted: var(--color-neutral-200);
  }
}
